import React, { useState } from "react";
import {
	AppBar,
	Toolbar,
	IconButton,
	Button,
	Drawer,
	List,
	ListItem,
	ListItemText,
	Hidden,
	useTheme,
	useMediaQuery,
	Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import Logo from "../imgs/testingart_logo.png";

export default function Navbar() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleLogout = (e) => {
		e.preventDefault();
		sessionStorage.setItem("isAuthenticated", false);
		window.location.reload();
	};

	const drawer = (
		<List>
			<ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
				<ListItemText primary="Home" />
			</ListItem>
			<ListItem button component={Link} to="/forms" onClick={handleDrawerToggle}>
				<ListItemText primary="Form" />
			</ListItem>
			<ListItem button component={Link} to="/image-upload" onClick={handleDrawerToggle}>
				<ListItemText primary="Image Upload" />
			</ListItem>
			<ListItem button component={Link} to="/table" onClick={handleDrawerToggle}>
				<ListItemText primary="Table" />
			</ListItem>
			<ListItem button component={Link} to="/api" onClick={handleDrawerToggle}>
				<ListItemText primary="API" />
			</ListItem>
			<ListItem button component={Link} href="www.e-commerce.com" onClick={handleDrawerToggle}>
				<ListItemText primary="E-Commerce" />
			</ListItem>
			<ListItem button onClick={handleLogout}>
				<ListItemText primary="Logout" />
			</ListItem>
		</List>
	);

	return (
		<div>
			<AppBar position="static">
				<Toolbar>
					{isMobile && (
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
						>
							<MenuIcon />
						</IconButton>
					)}
					<Box
						sx={{
							flexGrow: 1,
							display: "flex",
							justifyContent: isMobile ? "center" : "flex-start",
							alignItems: "center",
						}}
					>
						<a href="/" style={{ display: "flex", alignItems: "center" }}>
							<img src={Logo} alt="Logo" style={{ maxHeight: 50 }} />
						</a>
					</Box>
					<Hidden smDown>
						<Box sx={{ display: "flex", gap: 2 }}>
							<Button color="inherit" component={Link} to="/">
								Home
							</Button>
							<Button color="inherit" component={Link} to="/forms">
								Form
							</Button>
							<Button color="inherit" component={Link} to="/image-upload">
								Image Upload
							</Button>
							<Button color="inherit" component={Link} to="/table">
								Table
							</Button>
							<Button color="inherit" component={Link} to="/api">
								API
							</Button>

							<Button color="inherit" href="https://playground.testingart.com/e-commerce" target="_blank">
								E-Commerce
							</Button>
							<Button
								type="button"
								onClick={handleLogout}
								variant="contained"
								color="warning"
							>
								Logout
							</Button>
						</Box>
					</Hidden>
				</Toolbar>
			</AppBar>
			<Hidden smUp>
				<Drawer
					variant="temporary"
					anchor="left"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{drawer}
				</Drawer>
			</Hidden>
		</div>
	);
}
