import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormControl, FormLabel, Radio, RadioGroup } from "@mui/material";

export default function SignupPage() {
	const [isChecked, setIsChecked] = useState(false);
	const [gender, setGender] = useState("");
	const [formData, setFormData] = useState(null);
	const defaultTheme = createTheme();

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		const formDetails = {
			fname: data.get("firstName"),
			lname: data.get("lastName"),
			gender: gender,
			email: data.get("email"),
			password: data.get("password"),
			termsAndConditions: isChecked ? "checked" : "unchecked",
		};
		setFormData(formDetails);
	};

	const handleClick = (e) => {
		e.preventDefault();
		if (isChecked === false) {
			setIsChecked(true);
		} else setIsChecked(false);
	};

	const handleGenderSelection = (e) => {
		e.preventDefault();
		setGender(e.target.value);
	};

	return (
		<ThemeProvider theme={defaultTheme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Sign up
					</Typography>
					<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete="given-name"
									name="firstName"
									required
									fullWidth
									id="firstName"
									label="First Name"
									autoFocus
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									required
									fullWidth
									id="lastName"
									label="Last Name"
									name="lastName"
									autoComplete="family-name"
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl>
									<FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
									<RadioGroup
										row
										aria-labelledby="demo-row-radio-buttons-group-label"
										name="gender-radio-button"
										value={gender}
										onChange={handleGenderSelection}
									>
										<FormControlLabel
											value="female"
											id="female"
											control={<Radio />}
											label="Female"
										/>
										<FormControlLabel
											value="male"
											id="male"
											control={<Radio />}
											label="Male"
										/>
										<FormControlLabel
											value="other"
											id="other"
											control={<Radio />}
											label="Other"
										/>
									</RadioGroup>
								</FormControl>
							</Grid>

							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									autoComplete="email"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name="password"
									label="Password"
									type="password"
									id="password"
									autoComplete="new-password"
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Checkbox
											value={isChecked}
											color="primary"
											checked={isChecked}
											onClick={handleClick}
										/>
									}
									label="Accept Terms and Conditions"
									required
								/>
							</Grid>
						</Grid>
						<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
							Sign Up
						</Button>
						<Grid container justifyContent="flex-end">
							<Grid item>
								<Link href="/login" variant="body2">
									Already have an account? Sign in
								</Link>
							</Grid>
						</Grid>
						{formData && (
							<Box mt={5}>
								<Typography variant="h6">Submitted Data:</Typography>
								<Typography>
									First Name: <span id="firstNameResult">{formData.fname}</span>
								</Typography>
								<Typography>
									Last Name: <span id="lastNameResult">{formData.lname}</span>
								</Typography>
								<Typography>
									Gender: <span id="genderResult">{formData.gender}</span>
								</Typography>
								<Typography>
									Email: <span id="emailResult">{formData.email}</span>
								</Typography>
								<Typography>
									Password: <span id="passwordResult">{formData.password}</span>
								</Typography>
								<Typography>
									Checked the T&C:{" "}
									<span id="termsAndConditionsResult">{formData.termsAndConditions}</span>
								</Typography>
							</Box>
						)}
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	);
}
