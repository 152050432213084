import axios from "axios";

const API_BASE_URL = "https://practice.expandtesting.com/notes/api";

const api = axios.create({
	baseURL: API_BASE_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

api.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("token");
		if (token) {
			config.headers["X-Auth-Token"] = token; // Set X-Auth-Token header from local storage
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export const register = async (userData) => {
	return api.post("/users/register", userData);
};

export const login = async (userData) => {
	return api.post("/users/login", userData);
};

export const createNote = async (noteData, token) => {
	return api.post("/notes", noteData);
};

export const getNotes = async (token) => {
	return api.get("/notes");
};

export const updateNote = async (noteId, noteData, token) => {
	return api.put(`/notes/${noteId}`, noteData);
};

export const deleteNote = async (noteId, token) => {
	return api.delete(`/notes/${noteId}`);
};
