import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	Avatar,
	Button,
	CssBaseline,
	TextField,
	Box,
	Typography,
	Container,
	Grid,
	Link,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useAuth } from "../AuthContext";

export default function LoginPage() {
	const { isAuthenticated, setIsAuthenticated } = useAuth();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const navigate = useNavigate();
	const defaultEmail = "testingart@email.com";
	const defaultPassword = "Testing!123";
	const errorMessage = "Invalid Email or Password";
	const defaultTheme = createTheme();

	useEffect(() => {
		// Redirect if already authenticated
		if (isAuthenticated) {
			navigate("/");
		}
	}, [isAuthenticated, navigate]);

	const handleLogin = (e) => {
		e.preventDefault();

		if (email === defaultEmail && password === defaultPassword) {
			setIsAuthenticated(true);
			setError("");
			sessionStorage.setItem("isAuthenticated", true);
			navigate("/");
		} else {
			setError(errorMessage);
		}
	};

	return (
		<>
			<ThemeProvider theme={defaultTheme}>
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<Box
						sx={{
							marginTop: 8,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
							<LockOutlinedIcon />
						</Avatar>

						<Typography component="h1" variant="h5">
							Login
						</Typography>

						<Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
							<TextField
								margin="normal"
								required
								fullWidth
								id="email-field"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>

							<TextField
								margin="normal"
								required
								fullWidth
								id="password-field"
								label="Password"
								name="password"
								type="password"
								autoComplete="current-password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>

							<Button
								type="submit"
								id="submitBtn"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								Sign In
							</Button>
							<Grid container>
								<Grid item>
									<Link href="/signup" variant="body2">
										{"Don't have an account? Sign Up"}
									</Link>
								</Grid>
							</Grid>
							{error && (
								<Typography
									id="error-msg"
									color="error"
									variant="body2"
									sx={{ mt: 1 }}
								>
									{error}
								</Typography>
							)}

							<Typography variant="body2">email: testingart@email.com</Typography>
							<Typography variant="body2">password: Testing!123</Typography>
						</Box>
					</Box>
				</Container>
			</ThemeProvider>
		</>
	);
}
