import Navbar from "../components/Navbar";
import { useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { useFormik } from "formik";

const countries = {
	USA: [
		"New York",
		"Los Angeles",
		"Chicago",
		"Houston",
		"Phoenix",
		"Philadelphia",
		"San Antonio",
		"San Diego",
		"Dallas",
		"San Jose",
	],
	Canada: [
		"Toronto",
		"Vancouver",
		"Montreal",
		"Calgary",
		"Edmonton",
		"Ottawa",
		"Winnipeg",
		"Quebec City",
		"Hamilton",
		"Kitchener",
	],
	UK: [
		"London",
		"Manchester",
		"Liverpool",
		"Birmingham",
		"Leeds",
		"Glasgow",
		"Edinburgh",
		"Bristol",
		"Sheffield",
		"Newcastle",
	],
	Australia: [
		"Sydney",
		"Melbourne",
		"Brisbane",
		"Perth",
		"Adelaide",
		"Gold Coast",
		"Canberra",
		"Newcastle",
		"Wollongong",
		"Logan City",
	],
	Germany: [
		"Berlin",
		"Hamburg",
		"Munich",
		"Cologne",
		"Frankfurt",
		"Stuttgart",
		"Düsseldorf",
		"Dortmund",
		"Essen",
		"Leipzig",
	],
	France: [
		"Paris",
		"Marseille",
		"Lyon",
		"Toulouse",
		"Nice",
		"Nantes",
		"Strasbourg",
		"Montpellier",
		"Bordeaux",
		"Lille",
	],
	Italy: [
		"Rome",
		"Milan",
		"Naples",
		"Turin",
		"Palermo",
		"Genoa",
		"Bologna",
		"Florence",
		"Catania",
		"Bari",
	],
	Japan: [
		"Tokyo",
		"Osaka",
		"Nagoya",
		"Sapporo",
		"Fukuoka",
		"Kobe",
		"Kyoto",
		"Yokohama",
		"Hiroshima",
		"Sendai",
	],
	Brazil: [
		"São Paulo",
		"Rio de Janeiro",
		"Brasília",
		"Salvador",
		"Fortaleza",
		"Belo Horizonte",
		"Manaus",
		"Curitiba",
		"Recife",
		"Goiânia",
	],
	India: [
		"Mumbai",
		"Delhi",
		"Bengaluru",
		"Hyderabad",
		"Ahmedabad",
		"Chennai",
		"Kolkata",
		"Surat",
		"Pune",
		"Jaipur",
	],
	// Add more countries and cities as needed
};

const initialValues = {
	firstName: "",
	lastName: "",
	email: "",
	gender: "",
	hobbies: [],
	country: "",
	city: "",
};

export default function FormsPage() {
	const [formData, setFormData] = useState(null);

	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			setFormData(values);
		},
	});

	const handleCountryChange = (event, value) => {
		formik.setFieldValue("country", value);
		formik.setFieldValue("city", "");
	};

	const handleCityChange = (event, value) => {
		formik.setFieldValue("city", value);
	};

	return (
		<>
			<Navbar />
			<Box sx={{ mt: 3, maxWidth: "800px", margin: "50px auto" }}>
				<form onSubmit={formik.handleSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								id="firstName"
								name="firstName"
								label="First Name"
								value={formik.values.firstName}
								onChange={formik.handleChange}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								id="lastName"
								name="lastName"
								label="Last Name"
								value={formik.values.lastName}
								onChange={formik.handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								id="email"
								name="email"
								label="Email Address"
								value={formik.values.email}
								onChange={formik.handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Gender</FormLabel>
								<RadioGroup
									row
									aria-label="gender"
									name="gender"
									value={formik.values.gender}
									onChange={formik.handleChange}
								>
									<FormControlLabel
										value="female"
										control={<Radio />}
										label="Female"
									/>
									<FormControlLabel
										value="male"
										control={<Radio />}
										label="Male"
									/>
									<FormControlLabel
										value="other"
										control={<Radio />}
										label="Other"
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Hobbies</FormLabel>
								<FormControlLabel
									control={
										<Checkbox
											name="hobbies"
											value="Reading"
											checked={formik.values.hobbies.includes("Reading")}
											onChange={formik.handleChange}
										/>
									}
									label="Reading"
								/>
								<FormControlLabel
									control={
										<Checkbox
											name="hobbies"
											value="Traveling"
											checked={formik.values.hobbies.includes("Traveling")}
											onChange={formik.handleChange}
										/>
									}
									label="Traveling"
								/>
								<FormControlLabel
									control={
										<Checkbox
											name="hobbies"
											value="Cooking"
											checked={formik.values.hobbies.includes("Cooking")}
											onChange={formik.handleChange}
										/>
									}
									label="Cooking"
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Autocomplete
								id="country"
								options={Object.keys(countries)}
								value={formik.values.country}
								onChange={handleCountryChange}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Country"
										variant="outlined"
										fullWidth
									/>
								)}
								ListboxProps={{
									style: {
									  maxHeight: '150px', // Adjust the max height as needed
									  overflow: 'auto',
									},
								  }}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Autocomplete
								id="city"
								options={
									formik.values.country ? countries[formik.values.country] : []
								}
								value={formik.values.city}
								onChange={handleCityChange}
								renderInput={(params) => (
									<TextField
										{...params}
										label="City"
										variant="outlined"
										fullWidth
									/>
								)}
								ListboxProps={{
									style: {
									  maxHeight: '150px', // Adjust the max height as needed
									  overflow: 'auto',
									},
								  }}
								disabled={!formik.values.country}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button type="submit" fullWidth variant="contained">
								Submit
							</Button>
						</Grid>
					</Grid>
				</form>
				{formData && (
					<Box mt={5}>
						<Typography variant="h6">Submitted Data:</Typography>
						<Typography>First Name: {formData.firstName}</Typography>
						<Typography>Last Name: {formData.lastName}</Typography>
						<Typography>Email: {formData.email}</Typography>
						<Typography>Gender: {formData.gender}</Typography>
						<Typography>Hobbies: {formData.hobbies.join(", ")}</Typography>
						<Typography>Country: {formData.country}</Typography>
						<Typography>City: {formData.city}</Typography>
					</Box>
				)}
			</Box>
		</>
	);
}
