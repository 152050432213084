import { useState } from "react";
import Navbar from "../components/Navbar";
import Form from "../components/Form";
import Iframe from "../components/Iframe";

export default function ImageUploadPage() {
	const [formData, setFormData] = useState(null);

	const handleFormSubmit = (data) => {
		setFormData(data);
	};

	const styles = {
		appContainer: {
			textAlign: "center",
			fontFamily: "Arial, sans-serif",
		},
		header: {
			marginTop: "20px",
		},
	};

	return (
		<>
			<Navbar />
			<div style={styles.appContainer}>
				<h1 style={styles.header}>Form to Iframe Example</h1>
				<Form onSubmit={handleFormSubmit} />
				<Iframe formData={formData} />
			</div>
		</>
	);
}
