import React, { useState, useEffect } from "react";

export default function Iframe({ formData }) {
	const [content, setContent] = useState({
		title: "Default Title",
		description: "Default Description",
		image: null,
	});

	const [showIframe, setShowIframe] = useState(false);

	useEffect(() => {
		if (formData) {
			setShowIframe(false); // Hide the iframe while waiting
			const timer = setTimeout(() => {
				setContent(formData);
				setShowIframe(true); // Show the iframe after the delay
			}, 3000); // 3000 milliseconds = 3 seconds

			return () => clearTimeout(timer); // Cleanup the timer on component unmount or if formData changes
		}
	}, [formData]);

	const styles = {
		iframeContainer: {
			display: "flex",
			justifyContent: "center",
			marginTop: "50px",
		},
		iframe: {
			border: "1px solid #ccc",
			borderRadius: "4px",
			width: "600px",
			height: "400px",
		},
	};

	return (
		<div style={styles.iframeContainer}>
			{showIframe && (
				<iframe
					title="Result Iframe"
					style={styles.iframe}
					srcDoc={`
              <html>
                <body>
                  <h1>${content.title}</h1>
                  <p>${content.description}</p>
                  ${
					content.image
						? `<img src="${content.image}" alt="Uploaded Image" style="max-width: 100%;" />`
						: ""
				}
                </body>
              </html>
            `}
				/>
			)}
		</div>
	);
}
