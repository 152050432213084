import React, { useState } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Slider,
	MenuItem,
	FormControl,
	Select,
	InputLabel,
	Typography,
	Container,
	Checkbox,
	TableSortLabel,
} from "@mui/material";

export default function TableComp() {
	const initialItems = [
		{ id: 1, name: "Item 1", brand: "Brand A", price: 100 },
		{ id: 2, name: "Item 2", brand: "Brand B", price: 200 },
		{ id: 3, name: "Item 3", brand: "Brand A", price: 150 },
		{ id: 4, name: "Item 4", brand: "Brand C", price: 300 },
		// Add more items as needed
	];

	const [items] = useState(initialItems);
	const [priceRange, setPriceRange] = useState([0, 500]);
	const [selectedBrand, setSelectedBrand] = useState("");
	const [selectedItems, setSelectedItems] = useState([]);
	const [order, setOrder] = useState(null);
	const [orderBy, setOrderBy] = useState("");

	const handlePriceChange = (event, newValue) => {
		setPriceRange(newValue);
	};

	const handleBrandChange = (event) => {
		setSelectedBrand(event.target.value);
	};

	const handleCheckboxChange = (event, id) => {
		if (event.target.checked) {
			setSelectedItems([...selectedItems, id]);
		} else {
			setSelectedItems(selectedItems.filter((item) => item !== id));
		}
	};

	const handleRequestSort = (property) => {
		if (orderBy === property) {
			if (order === "asc") {
				setOrder("desc");
			} else if (order === "desc") {
				setOrder(null);
				setOrderBy("");
			} else {
				setOrder("asc");
			}
		} else {
			setOrder("asc");
			setOrderBy(property);
		}
	};

	const sortedItems = () => {
		if (order === null) {
			return items;
		}
		return items.slice().sort((a, b) => {
			if (orderBy === "price" || orderBy === "id") {
				return order === "asc" ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
			} else {
				return order === "asc" ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]);
			}
		});
	};

	const filteredItems = sortedItems().filter((item) => {
		return (
			item.price >= priceRange[0] &&
			item.price <= priceRange[1] &&
			(selectedBrand ? item.brand === selectedBrand : true)
		);
	});

	return (
		<Container maxWidth="md">
			<Box sx={{ padding: 3, textAlign: "center" }}>
				<Typography variant="h4" gutterBottom>
					Store Inventory
				</Typography>
				<Box sx={{ marginBottom: 3 }}>
					<Typography variant="h6" gutterBottom>
						Filter Items
					</Typography>
					<Box sx={{ marginBottom: 2 }}>
						<Typography gutterBottom>Price Range</Typography>
						<Slider
							value={priceRange}
							onChange={handlePriceChange}
							valueLabelDisplay="auto"
							min={0}
							max={500}
							sx={{ width: "80%", margin: "0 auto" }}
							id="price-slider"
						/>
					</Box>
					<Box sx={{ marginBottom: 2, width: "80%", margin: "0 auto" }}>
						<FormControl fullWidth>
							<InputLabel id="brand-select-label">Brand</InputLabel>
							<Select
								labelId="brand-select-label"
								value={selectedBrand}
								onChange={handleBrandChange}
								label="Brand"
								id="brand-select"
							>
								<MenuItem value="">
									<em>All</em>
								</MenuItem>
								<MenuItem value="Brand A">Brand A</MenuItem>
								<MenuItem value="Brand B">Brand B</MenuItem>
								<MenuItem value="Brand C">Brand C</MenuItem>
							</Select>
						</FormControl>
					</Box>
				</Box>
				<TableContainer component={Paper} sx={{ margin: "0 auto", width: "90%" }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Select</TableCell>
								<TableCell>
									<TableSortLabel
										active={orderBy === "id"}
										direction={orderBy === "id" ? order : "asc"}
										onClick={() => handleRequestSort("id")}
									>
										ID
									</TableSortLabel>
								</TableCell>
								<TableCell>
									<TableSortLabel
										active={orderBy === "name"}
										direction={orderBy === "name" ? order : "asc"}
										onClick={() => handleRequestSort("name")}
									>
										Name
									</TableSortLabel>
								</TableCell>
								<TableCell>
									<TableSortLabel
										active={orderBy === "brand"}
										direction={orderBy === "brand" ? order : "asc"}
										onClick={() => handleRequestSort("brand")}
									>
										Brand
									</TableSortLabel>
								</TableCell>
								<TableCell>
									<TableSortLabel
										active={orderBy === "price"}
										direction={orderBy === "price" ? order : "asc"}
										onClick={() => handleRequestSort("price")}
									>
										Price
									</TableSortLabel>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredItems.map((item) => (
								<TableRow key={item.id} id={`row-${item.id}`}>
									<TableCell padding="checkbox">
										<Checkbox
											id={`checkbox-${item.id}`}
											checked={selectedItems.includes(item.id)}
											onChange={(event) => handleCheckboxChange(event, item.id)}
										/>
									</TableCell>
									<TableCell id={`cell-id-${item.id}`}>{item.id}</TableCell>
									<TableCell id={`cell-name-${item.id}`}>{item.name}</TableCell>
									<TableCell id={`cell-brand-${item.id}`}>{item.brand}</TableCell>
									<TableCell id={`cell-price-${item.id}`}>{item.price}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Container>
	);
}