import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import { useAuth } from "./AuthContext";

import FormsPage from "./pages/FormsPage";
import ImageUploadPage from "./pages/ImageUploadPage";
import TablePage from "./pages/TablePage";
import APIBookingPage from "./pages/APIBookingPage";

function App() {
	const { isAuthenticated } = useAuth();

	return (
		<>
			<Routes>
				<Route
					path="/"
					element={isAuthenticated ? <HomePage /> : <Navigate to="/login" replace />}
				/>
				<Route
					path="/login"
					element={!isAuthenticated ? <LoginPage /> : <Navigate to="/" replace />}
				/>
				<Route path="/signup" element={<SignupPage />} />
				<Route
					path="/forms"
					element={isAuthenticated ? <FormsPage /> : <Navigate to="/login" replace />}
				/>
				<Route
					path="/image-upload"
					element={isAuthenticated ? <ImageUploadPage /> : <Navigate to="/login" replace />}
				/>
				<Route
					path="/table"
					element={isAuthenticated ? <TablePage /> : <Navigate to="/login" replace />}
				/>
				<Route
					path="/api"
					element={isAuthenticated ? <APIBookingPage /> : <Navigate to="/login" replace />}
				/>
			</Routes>
		</>
	);
}

export default App;
