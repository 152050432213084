import React, { useState, useEffect } from "react";
import {
	TextField,
	Button,
	Container,
	Typography,
	Box,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from "@mui/material";
import { getNotes, createNote, updateNote, deleteNote } from "../api/Api";

export default function NotesPage({ user, setUser }) {
	const [notes, setNotes] = useState([]);
	const [noteInput, setNoteInput] = useState({
		title: "",
		description: "",
		category: "",
	});

	const fetchNotes = async () => {
		try {
			const response = await getNotes(user.token);
			console.log(response.data); // Log the API response data
			setNotes(response.data.data); // Assuming response.data is the array of notes
		} catch (error) {
			console.error("Failed to fetch notes:", error);
		}
	};

	useEffect(() => {
		fetchNotes();
	}, [user.token]);

	const handleLogout = () => {
		localStorage.removeItem("token"); // Remove the token from local storage
		setUser(null); // Update the user state to reflect that no user is logged in
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setNoteInput((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleAddNote = async () => {
		const { title, description, category } = noteInput;
		if (!title || !description || !category) {
			alert("All fields are required");
			return;
		}
		await createNote({ title, description, category }, user.token);
		setNoteInput({ title: "", description: "", category: "" }); // Clear the form fields
		fetchNotes(); // Refresh the notes list
	};

	const handleUpdateNote = async (id, currentNote) => {
		await updateNote(id, currentNote, user.token);
		fetchNotes(); // Refresh the notes list
	};

	const handleDeleteNote = async (id) => {
		await deleteNote(id, user.token);
		fetchNotes(); // Refresh the notes list after deletion
	};

	return (
		<Container maxWidth="xs">
			<Typography variant="h4">Notes</Typography>
			<Button variant="contained" color="secondary" onClick={handleLogout}>
				Logout
			</Button>
			<Box component="form" noValidate autoComplete="off">
				<TextField
					name="title"
					label="Title"
					value={noteInput.title}
					onChange={handleChange}
					fullWidth
					margin="normal"
				/>
				<TextField
					name="description"
					label="Description"
					value={noteInput.description}
					onChange={handleChange}
					fullWidth
					margin="normal"
				/>
				<FormControl fullWidth margin="normal">
					<InputLabel id="category-label">Category</InputLabel>
					<Select
						labelId="category-label"
						name="category"
						value={noteInput.category}
						label="Category"
						onChange={handleChange}
					>
						<MenuItem value="Home">Home</MenuItem>
						<MenuItem value="Work">Work</MenuItem>
						<MenuItem value="Personal">Personal</MenuItem>
					</Select>
				</FormControl>
				<Button variant="contained" color="primary" onClick={handleAddNote}>
					Add Note
				</Button>
			</Box>

			{Array.isArray(notes) &&
				notes.map(
					(
						note // Check if notes is an array
					) => (
						<Box
							key={note.id}
							sx={{
								mt: 2,
								p: 1,
								border: "1px solid grey",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Box sx={{ flex: "1 1 auto" }}>
								<Typography variant="h6">{note.title}</Typography>
								<Typography>{note.description}</Typography>
								<Typography variant="caption">{note.category}</Typography>
							</Box>
							<Button onClick={() => handleDeleteNote(note.id)} color="error">
								Delete
							</Button>
						</Box>
					)
				)}
		</Container>
	);
}
