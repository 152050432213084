import React, { useState, useEffect } from "react";
import LoginPage from "../sub-pages/APILoginPage";
import NotesPage from "../sub-pages/NotesPages";
import Navbar from "../components/Navbar";

export default function APIBookingPage() {
	const [user, setUser] = useState(null);

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token) {
			setUser({ token });
		}
	}, []);

	return (
		<>
			<Navbar />
			{user ? <NotesPage user={user} setUser={setUser} /> : <LoginPage setUser={setUser} />}
		</>
	);
}
