import React, { useState } from "react";
import { TextField, Button, Container, Typography, Box, Tab, Tabs } from "@mui/material";
import { login, register } from "../api/Api";

export default function APILoginPage({ setUser }) {
	const [tab, setTab] = useState(0);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleLogin = async () => {
		try {
			const response = await login({ email, password });
			localStorage.setItem("token", response.data.data.token); // Save token to local storage
			setUser({ token: response.data.token });
			console.log(response.data.data.token);
			alert("Login successful");
		} catch (error) {
			console.error("Login failed:", error);
			alert("Login failed");
		}
	};

	const handleRegister = async () => {
		try {
			const response = await register({ name, email, password });
			localStorage.setItem("token", response.data.token); // Save token to local storage
			setUser({ token: response.data.token });
			alert(response.data);
			alert("Registration successful");
		} catch (error) {
			console.error("Registration failed:", error);
			alert("Registration failed");
		}
	};

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

	return (
		<Container component="main" maxWidth="xs">
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs value={tab} onChange={handleTabChange} aria-label="login register tabs">
					<Tab label="Login" />
					<Tab label="Register" />
				</Tabs>
			</Box>
			{tab === 0 && (
				<Box>
					<Typography variant="h4" gutterBottom>
						Login
					</Typography>
					<TextField
						label="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						fullWidth
						margin="normal"
					/>
					<TextField
						label="Password"
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						fullWidth
						margin="normal"
					/>
					<Button variant="contained" onClick={handleLogin}>
						Login
					</Button>

					<Typography style={{ marginTop: "10px" }}>email: testingart@email.com</Typography>
					<Typography>password: Testing!123</Typography>
				</Box>
			)}
			{tab === 1 && (
				<Box>
					<Typography variant="h4" gutterBottom>
						Register
					</Typography>
					<TextField
						label="Name"
						value={name}
						onChange={(e) => setName(e.target.value)}
						fullWidth
						margin="normal"
					/>
					<TextField
						label="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						fullWidth
						margin="normal"
					/>
					<TextField
						label="Password"
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						fullWidth
						margin="normal"
					/>
					<Button variant="contained" onClick={handleRegister}>
						Register
					</Button>
				</Box>
			)}
		</Container>
	);
}
