import React, { useState } from "react";

export default function Form({ onSubmit }) {
	const [formData, setFormData] = useState({ title: "", description: "", image: null });
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFormData({ ...formData, image: URL.createObjectURL(file) });
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsSubmitting(true);
		onSubmit(formData);
		setTimeout(() => {
			setIsSubmitting(false);
		}, 5000);
	};

	const styles = {
		formContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			marginTop: "50px",
		},
		label: {
			marginBottom: "10px",
		},
		input: {
			padding: "8px",
			width: "300px",
			marginBottom: "20px",
			borderRadius: "4px",
			border: "1px solid #ccc",
		},
		button: {
			padding: "10px 20px",
			backgroundColor: "#007bff",
			color: "white",
			border: "none",
			borderRadius: "4px",
			cursor: "pointer",
		},
		buttonDisabled: {
			padding: "10px 20px",
			backgroundColor: "#cccccc",
			color: "white",
			border: "none",
			borderRadius: "4px",
			cursor: "not-allowed",
		},
	};

	return (
		<form onSubmit={handleSubmit} style={styles.formContainer}>
			<label style={styles.label}>
				Title:
				<input
					type="text"
					name="title"
					value={formData.title}
					onChange={handleChange}
					style={styles.input}
				/>
			</label>
			<label style={styles.label}>
				Description:
				<input
					type="text"
					name="description"
					value={formData.description}
					onChange={handleChange}
					style={styles.input}
				/>
			</label>
			<label style={styles.label}>
				Upload Image:
				<input type="file" accept="image/*" onChange={handleImageChange} style={styles.input} />
			</label>
			<button
				type="submit"
				style={isSubmitting ? styles.buttonDisabled : styles.button}
				disabled={isSubmitting}
			>
				{isSubmitting ? "Submitting..." : "Submit"}
			</button>
		</form>
	);
}
