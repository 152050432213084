import Navbar from "../components/Navbar";
import TableComp from "../components/Table";

export default function TablePage() {
	return (
		<>
			<Navbar />
			<h1>Table Page</h1>
			<TableComp />
		</>
	);
}
