import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Navbar from "../components/Navbar";

export default function HomePage() {
	const [showMore, setShowMore] = useState(false);

	const handleToggleShowMore = () => {
		setShowMore(!showMore);
	};

	const text = `
	Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
	molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
	numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
	optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
	obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam
	nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit,
	tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit,
	quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos 
	sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam
	recusandae alias error harum maxime adipisci amet laborum. Perspiciatis 
	minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit 
	quibusdam sed amet tempora. Sit laborum ab, eius fugit doloribus tenetur 
	fugiat, temporibus enim commodi iusto libero magni deleniti quod quam 
	consequuntur! Commodi minima excepturi repudiandae velit hic maxime
	doloremque. Quaerat provident commodi consectetur veniam similique ad 
	earum omnis ipsum saepe, voluptas, hic voluptates pariatur est explicabo 
	fugiat, dolorum eligendi quam cupiditate excepturi mollitia maiores labore 
	suscipit quas? Nulla, placeat. Voluptatem quaerat non architecto ab laudantium
	modi minima sunt esse temporibus sint culpa, recusandae aliquam numquam 
	totam ratione voluptas quod exercitationem fuga. Possimus quis earum veniam 
	quasi aliquam eligendi, placeat qui corporis!`;

	const truncatedText = text.substring(0, 200); // Adjust this number to show more or less of the initial text

	return (
		<>
			<Navbar />
			<div>
				<Box
					sx={{
						maxWidth: "800px",
						margin: "50px auto",
						padding: "20px",
						textAlign: "center",
						backgroundColor: "#f5f5f5",
						borderRadius: "8px",
						boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
					}}
				>
					<Typography
						variant="h1"
						component="h1"
						sx={{ marginBottom: "20px", fontSize: "2.5rem", fontWeight: "bold" }}
					>
						Welcome to Automation Testing Playground
					</Typography>
					<Typography
						variant="body1"
						component="p"
						sx={{ fontSize: "1rem", lineHeight: "1.6" }}
					>
						{showMore ? text : `${truncatedText}...`}
					</Typography>
					<Button onClick={handleToggleShowMore}>
						{showMore ? "Show Less" : "Show More"}
					</Button>
				</Box>
				<Box></Box>
			</div>
		</>
	);
}
